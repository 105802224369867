import { Configuration as BffConfiguration } from '../openapi/bff';
import { Configuration as WandererConfiguration } from '../openapi/wanderer';
import { Configuration as YentaConfiguration } from '../openapi/yenta';
import { Configuration as ArtemisConfiguration } from '../openapi/artemis';
import env from './env';

export const getBFFConfiguration = (): BffConfiguration =>
  new BffConfiguration({
    basePath: env.NEXT_PUBLIC_API_BASE_BFF,
  });

export const getWandererConfiguration = (): BffConfiguration =>
  new WandererConfiguration({
    basePath: env.NEXT_PUBLIC_API_BASE_WANDERER,
  });

export const getYentaConfiguration = (): YentaConfiguration =>
  new YentaConfiguration({
    basePath: env.NEXT_PUBLIC_API_BASE_YENTA,
  });

export const getArtemisConfiguration = (): ArtemisConfiguration =>
  new ArtemisConfiguration({
    basePath: env.NEXT_PUBLIC_API_BASE_ARTEMIS,
  });

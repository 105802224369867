/* tslint:disable */
/* eslint-disable */
/**
 * Artemis API
 * Artemis Main Application
 *
 * The version of the OpenAPI document: 4
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface Filter
 */
export interface Filter {
    /**
     * 
     * @type {string}
     * @memberof Filter
     */
    'listingType'?: FilterListingTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'minPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'maxPrice'?: number;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'minBeds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'maxBeds'?: number;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'minBaths'?: number;
    /**
     * 
     * @type {number}
     * @memberof Filter
     */
    'maxBaths'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    'propertyTypes'?: Array<FilterPropertyTypesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Filter
     */
    'amenities'?: Array<FilterAmenitiesEnum>;
}

export const FilterListingTypeEnum = {
    Buy: 'BUY',
    Rent: 'RENT',
    Unknown: 'UNKNOWN'
} as const;

export type FilterListingTypeEnum = typeof FilterListingTypeEnum[keyof typeof FilterListingTypeEnum];
export const FilterPropertyTypesEnum = {
    VacantLand: 'VACANT_LAND',
    MultiFamily: 'MULTI_FAMILY',
    SingleFamily: 'SINGLE_FAMILY',
    Condo: 'CONDO',
    Retail: 'RETAIL',
    Office: 'OFFICE',
    Hospitality: 'HOSPITALITY',
    Business: 'BUSINESS',
    Agriculture: 'AGRICULTURE',
    Parking: 'PARKING',
    Recreational: 'RECREATIONAL',
    Industrial: 'INDUSTRIAL',
    Other: 'OTHER'
} as const;

export type FilterPropertyTypesEnum = typeof FilterPropertyTypesEnum[keyof typeof FilterPropertyTypesEnum];
export const FilterAmenitiesEnum = {
    Pool: 'POOL',
    HotTub: 'HOT_TUB',
    Garage: 'GARAGE',
    Basement: 'BASEMENT',
    Fireplace: 'FIREPLACE',
    AirConditioning: 'AIR_CONDITIONING',
    Waterfront: 'WATERFRONT',
    WasherDryerHookup: 'WASHER_DRYER_HOOKUP',
    PetsAllowed: 'PETS_ALLOWED',
    Backyard: 'BACKYARD',
    Golf: 'GOLF',
    Porch: 'PORCH',
    PatioDeck: 'PATIO_DECK',
    TennisCourt: 'TENNIS_COURT',
    Other: 'OTHER'
} as const;

export type FilterAmenitiesEnum = typeof FilterAmenitiesEnum[keyof typeof FilterAmenitiesEnum];

/**
 * 
 * @export
 * @interface GeoPoint
 */
export interface GeoPoint {
    /**
     * 
     * @type {number}
     * @memberof GeoPoint
     */
    'lat'?: number;
    /**
     * 
     * @type {number}
     * @memberof GeoPoint
     */
    'lon'?: number;
}
/**
 * 
 * @export
 * @interface LatLong
 */
export interface LatLong {
    /**
     * 
     * @type {number}
     * @memberof LatLong
     */
    'lat'?: number;
    /**
     * 
     * @type {number}
     * @memberof LatLong
     */
    'lon'?: number;
}
/**
 * 
 * @export
 * @interface RealAddress
 */
export interface RealAddress {
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'streetAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'addressLine1'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'addressLine2'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'streetNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'streetDirectionPrefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'streetName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'streetSuffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'streetDirectionSuffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'unitNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'boxNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'city'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'province'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'postalCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'country'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'additionalStreetInfo'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'communityName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'neighbourhood'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAddress
     */
    'subdivision'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealAddress
     */
    'latitude'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealAddress
     */
    'longitude'?: number;
}
/**
 * 
 * @export
 * @interface RealAgentDetails
 */
export interface RealAgentDetails {
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'mlsId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'firstName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'middleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'lastName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'namePrefix'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'nameSuffix'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'nickname'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealAgentDetails
     */
    'lastUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'position'?: string;
    /**
     * 
     * @type {Array<RealWebsite>}
     * @memberof RealAgentDetails
     */
    'websites'?: Array<RealWebsite>;
    /**
     * 
     * @type {Array<RealPhone>}
     * @memberof RealAgentDetails
     */
    'phones'?: Array<RealPhone>;
    /**
     * 
     * @type {RealOfficeDetails}
     * @memberof RealAgentDetails
     */
    'office'?: RealOfficeDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealAgentDetails
     */
    'educationCredentials'?: Array<string>;
    /**
     * 
     * @type {Array<RealPhoto>}
     * @memberof RealAgentDetails
     */
    'photos'?: Array<RealPhoto>;
    /**
     * 
     * @type {RealAddress}
     * @memberof RealAgentDetails
     */
    'address'?: RealAddress;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealAgentDetails
     */
    'specialties'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealAgentDetails
     */
    'designations'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealAgentDetails
     */
    'languages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealAgentDetails
     */
    'tradingAreas'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'status'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'officeNationalAssociationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'nationalAssociationId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'aor'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'aorKey'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'fax'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'pager'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealAgentDetails
     */
    'originalEntryTimestamp'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RealAgentDetails
     */
    'hasEmail'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RealAgentDetails
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface RealAlternateURL
 */
export interface RealAlternateURL {
    /**
     * 
     * @type {string}
     * @memberof RealAlternateURL
     */
    'brochureLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAlternateURL
     */
    'mapLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAlternateURL
     */
    'photoLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAlternateURL
     */
    'soundLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAlternateURL
     */
    'videoLink'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealAlternateURL
     */
    'otherLink'?: string;
}
/**
 * 
 * @export
 * @interface RealBathrooms
 */
export interface RealBathrooms {
    /**
     * 
     * @type {number}
     * @memberof RealBathrooms
     */
    'bathroomsTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBathrooms
     */
    'bathroomsPartial'?: number;
}
/**
 * 
 * @export
 * @interface RealBedrooms
 */
export interface RealBedrooms {
    /**
     * 
     * @type {number}
     * @memberof RealBedrooms
     */
    'bedroomsAboveGround'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBedrooms
     */
    'bedroomsBelowGround'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBedrooms
     */
    'bedroomsTotal'?: number;
}
/**
 * 
 * @export
 * @interface RealBuilding
 */
export interface RealBuilding {
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'aboveGradeFinishedArea'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'aboveGradeFinishedAreaUnits'?: string;
    /**
     * 
     * @type {RealBathrooms}
     * @memberof RealBuilding
     */
    'bathrooms'?: RealBathrooms;
    /**
     * 
     * @type {RealBedrooms}
     * @memberof RealBuilding
     */
    'bedrooms'?: RealBedrooms;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'age'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'amenities'?: Array<RealBuildingAmenitiesEnum>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'amperage'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'anchor'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'anchorsCoTenants'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'appliances'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'architecturalStyle'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'associationFeeIncludes'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'availabilityDate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'basementDevelopment'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'basementFeatures'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'basementType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'bomaRating'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'ceilingHeight'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'ceilingType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'clearCeilingHeight'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'commonInterest'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'communityFeatures'?: Array<string>;
    /**
     * 
     * @type {RealConstructionDetails}
     * @memberof RealBuilding
     */
    'constructionDetails'?: RealConstructionDetails;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'coolingType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'directions'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'electric'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'energuideRating'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'exteriorFinish'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'fireProtection'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'fireplaceFuel'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealBuilding
     */
    'fireplacePresent'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'fireplaceTotal'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'fireplaceType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'fixture'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'flooringType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'foundationType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'heatingFuel'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'heatingType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'inclusions'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RealBuilding
     */
    'internetAddressDisplay'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RealBuilding
     */
    'internetEntireListingDisplay'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'livingArea'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'numberOfUnitsTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'originatingSystemName'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'parcelNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'photosChangeTimestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'photosCount'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'possibleUse'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'propertyCondition'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'leedsCategory'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'leedsRating'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'renovatedDate'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'roofMaterial'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'roofStyle'?: Array<string>;
    /**
     * 
     * @type {Array<RealRoom>}
     * @memberof RealBuilding
     */
    'rooms'?: Array<RealRoom>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'securityFeatures'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'storiesTotal'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'sizeExterior'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'sizeInterior'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'standardStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'statusChangeTimestamp'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'totalActualRent'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'totalBuildings'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealBuilding
     */
    'sizeInteriorFinished'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'storeFront'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'totalFinishedArea'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'type'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'uffi'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'unitType'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'utilityPower'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'utilityWater'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealBuilding
     */
    'vacancyRate'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealBuilding
     */
    'waterSource'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealBuilding
     */
    'propertyAttached'?: boolean;
}

export const RealBuildingAmenitiesEnum = {
    Pool: 'POOL',
    HotTub: 'HOT_TUB',
    Garage: 'GARAGE',
    Basement: 'BASEMENT',
    Fireplace: 'FIREPLACE',
    AirConditioning: 'AIR_CONDITIONING',
    Waterfront: 'WATERFRONT',
    WasherDryerHookup: 'WASHER_DRYER_HOOKUP',
    PetsAllowed: 'PETS_ALLOWED',
    Backyard: 'BACKYARD',
    Golf: 'GOLF',
    Porch: 'PORCH',
    PatioDeck: 'PATIO_DECK',
    TennisCourt: 'TENNIS_COURT',
    Other: 'OTHER'
} as const;

export type RealBuildingAmenitiesEnum = typeof RealBuildingAmenitiesEnum[keyof typeof RealBuildingAmenitiesEnum];

/**
 * 
 * @export
 * @interface RealBusiness
 */
export interface RealBusiness {
    /**
     * 
     * @type {string}
     * @memberof RealBusiness
     */
    'businessType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBusiness
     */
    'businessSubType'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealBusiness
     */
    'establishedDate'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealBusiness
     */
    'franchise'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealBusiness
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealBusiness
     */
    'operatingSince'?: number;
}
/**
 * 
 * @export
 * @interface RealCharacteristics
 */
export interface RealCharacteristics {
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'features'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealCharacteristics
     */
    'propertyType'?: RealCharacteristicsPropertyTypeEnum;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'structureType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealCharacteristics
     */
    'zoningType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealCharacteristics
     */
    'zoningDescription'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'communicationType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'rentalEquipmentType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'easement'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'poolType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'poolFeatures'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'rightType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'roadType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'liveStockType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'machinery'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'storageType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'equipmentType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'farmType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'irrigationType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'structure'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'signType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'loadingType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'crop'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'viewType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'waterFrontType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealCharacteristics
     */
    'waterFrontName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'communityFeatures'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealCharacteristics
     */
    'otherEquipment'?: Array<string>;
}

export const RealCharacteristicsPropertyTypeEnum = {
    VacantLand: 'VACANT_LAND',
    MultiFamily: 'MULTI_FAMILY',
    SingleFamily: 'SINGLE_FAMILY',
    Condo: 'CONDO',
    Retail: 'RETAIL',
    Office: 'OFFICE',
    Hospitality: 'HOSPITALITY',
    Business: 'BUSINESS',
    Agriculture: 'AGRICULTURE',
    Parking: 'PARKING',
    Recreational: 'RECREATIONAL',
    Industrial: 'INDUSTRIAL',
    Other: 'OTHER'
} as const;

export type RealCharacteristicsPropertyTypeEnum = typeof RealCharacteristicsPropertyTypeEnum[keyof typeof RealCharacteristicsPropertyTypeEnum];

/**
 * 
 * @export
 * @interface RealConstructionDetails
 */
export interface RealConstructionDetails {
    /**
     * 
     * @type {number}
     * @memberof RealConstructionDetails
     */
    'yearBuilt'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealConstructionDetails
     */
    'constructionMaterial'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealConstructionDetails
     */
    'constructionStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealConstructionDetails
     */
    'constructionStyleAttachment'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealConstructionDetails
     */
    'constructionStyleOther'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealConstructionDetails
     */
    'constructionStyleSplitLevel'?: string;
}
/**
 * 
 * @export
 * @interface RealEvent
 */
export interface RealEvent {
    /**
     * 
     * @type {number}
     * @memberof RealEvent
     */
    'startDateTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealEvent
     */
    'endDateTime'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealEvent
     */
    'comments'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealEvent
     */
    'url'?: string;
}
/**
 * 
 * @export
 * @interface RealImage
 */
export interface RealImage {
    /**
     * 
     * @type {string}
     * @memberof RealImage
     */
    'url'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealImage
     */
    'width'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealImage
     */
    'format'?: string;
}
/**
 * 
 * @export
 * @interface RealLand
 */
export interface RealLand {
    /**
     * 
     * @type {number}
     * @memberof RealLand
     */
    'lotSizeArea'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealLand
     */
    'sizeTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealLand
     */
    'sizeTotalText'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealLand
     */
    'sizeFrontage'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'accessType'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealLand
     */
    'acreage'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'amenities'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RealLand
     */
    'clearedTotal'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'currentUse'?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RealLand
     */
    'divisible'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealLand
     */
    'fenceTotal'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'fenceType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealLand
     */
    'frontsOn'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'landDisposition'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'landscapeFeatures'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RealLand
     */
    'pastureTotal'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'sewer'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealLand
     */
    'sizeDepth'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealLand
     */
    'sizeIrregular'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealLand
     */
    'soilEvaluation'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'soilType'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealLand
     */
    'surfaceWater'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof RealLand
     */
    'tiledTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealLand
     */
    'topographyType'?: string;
}
/**
 * 
 * @export
 * @interface RealListingInformation
 */
export interface RealListingInformation {
    /**
     * 
     * @type {number}
     * @memberof RealListingInformation
     */
    'price'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealListingInformation
     */
    'pricePerTime'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealListingInformation
     */
    'pricePerUnit'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealListingInformation
     */
    'listingContractDate'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealListingInformation
     */
    'documentsAvailable'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'publicRemarks'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'locationDescription'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'plan'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealListingInformation
     */
    'maintenanceFee'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'maintenanceFeePaymentUnit'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealListingInformation
     */
    'maintenanceFeeType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'managementCompany'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealListingInformation
     */
    'lease'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'leasePerTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'leasePerUnit'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'leaseTermRemaining'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'easeTermRemainingFreq'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealListingInformation
     */
    'leaseType'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'transactionType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'ownershipType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'countyID'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'stateOrProvince'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'additionalInformationIndicator'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealListingInformation
     */
    'moreInformationLink'?: string;
}
/**
 * 
 * @export
 * @interface RealLogo
 */
export interface RealLogo {
    /**
     * 
     * @type {string}
     * @memberof RealLogo
     */
    'thumbnailURL'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealLogo
     */
    'logoLastUpdated'?: number;
}
/**
 * 
 * @export
 * @interface RealOfficeDetails
 */
export interface RealOfficeDetails {
    /**
     * 
     * @type {string}
     * @memberof RealOfficeDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealOfficeDetails
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealOfficeDetails
     */
    'name'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealOfficeDetails
     */
    'lastUpdated'?: number;
    /**
     * 
     * @type {RealLogo}
     * @memberof RealOfficeDetails
     */
    'logo'?: RealLogo;
    /**
     * 
     * @type {string}
     * @memberof RealOfficeDetails
     */
    'organizationType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealOfficeDetails
     */
    'designation'?: string;
    /**
     * 
     * @type {RealAddress}
     * @memberof RealOfficeDetails
     */
    'realAddress'?: RealAddress;
    /**
     * 
     * @type {Array<RealPhone>}
     * @memberof RealOfficeDetails
     */
    'phones'?: Array<RealPhone>;
    /**
     * 
     * @type {Array<RealWebsite>}
     * @memberof RealOfficeDetails
     */
    'websites'?: Array<RealWebsite>;
    /**
     * 
     * @type {string}
     * @memberof RealOfficeDetails
     */
    'franchiser'?: string;
}
/**
 * 
 * @export
 * @interface RealParking
 */
export interface RealParking {
    /**
     * 
     * @type {string}
     * @memberof RealParking
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealParking
     */
    'spaces'?: string;
}
/**
 * 
 * @export
 * @interface RealParkingDetails
 */
export interface RealParkingDetails {
    /**
     * 
     * @type {number}
     * @memberof RealParkingDetails
     */
    'parkingSpaceTotal'?: number;
    /**
     * 
     * @type {Array<RealParking>}
     * @memberof RealParkingDetails
     */
    'parkingSpaces'?: Array<RealParking>;
}
/**
 * 
 * @export
 * @interface RealPhone
 */
export interface RealPhone {
    /**
     * 
     * @type {string}
     * @memberof RealPhone
     */
    'phone'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealPhone
     */
    'contactType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealPhone
     */
    'phoneType'?: string;
}
/**
 * 
 * @export
 * @interface RealPhoto
 */
export interface RealPhoto {
    /**
     * 
     * @type {number}
     * @memberof RealPhoto
     */
    'sequenceID'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealPhoto
     */
    'caption'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealPhoto
     */
    'lastUpdated'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealPhoto
     */
    'subjectType'?: string;
    /**
     * 
     * @type {RealResponsiveImages}
     * @memberof RealPhoto
     */
    'realResponsiveImages'?: RealResponsiveImages;
    /**
     * 
     * @type {boolean}
     * @memberof RealPhoto
     */
    'preferred'?: boolean;
}
/**
 * 
 * @export
 * @interface RealPropertiesResponse
 */
export interface RealPropertiesResponse {
    /**
     * 
     * @type {number}
     * @memberof RealPropertiesResponse
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof RealPropertiesResponse
     */
    'pageSize'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RealPropertiesResponse
     */
    'hasNextPage'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RealPropertiesResponse
     */
    'totalCount'?: number;
    /**
     * 
     * @type {Array<RealPropertyDetails>}
     * @memberof RealPropertiesResponse
     */
    'realPropertyDetailsList'?: Array<RealPropertyDetails>;
}
/**
 * 
 * @export
 * @interface RealPropertyDetails
 */
export interface RealPropertyDetails {
    /**
     * 
     * @type {string}
     * @memberof RealPropertyDetails
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealPropertyDetails
     */
    'source'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealPropertyDetails
     */
    'sourceId'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealPropertyDetails
     */
    'listingId'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealPropertyDetails
     */
    'lastUpdated'?: number;
    /**
     * 
     * @type {Array<RealAgentDetails>}
     * @memberof RealPropertyDetails
     */
    'agentList'?: Array<RealAgentDetails>;
    /**
     * 
     * @type {RealAddress}
     * @memberof RealPropertyDetails
     */
    'address'?: RealAddress;
    /**
     * 
     * @type {string}
     * @memberof RealPropertyDetails
     */
    'board'?: string;
    /**
     * 
     * @type {RealBusiness}
     * @memberof RealPropertyDetails
     */
    'business'?: RealBusiness;
    /**
     * 
     * @type {RealBuilding}
     * @memberof RealPropertyDetails
     */
    'building'?: RealBuilding;
    /**
     * 
     * @type {RealLand}
     * @memberof RealPropertyDetails
     */
    'land'?: RealLand;
    /**
     * 
     * @type {RealAlternateURL}
     * @memberof RealPropertyDetails
     */
    'alternateURL'?: RealAlternateURL;
    /**
     * 
     * @type {Array<RealEvent>}
     * @memberof RealPropertyDetails
     */
    'openHouse'?: Array<RealEvent>;
    /**
     * 
     * @type {Array<RealEvent>}
     * @memberof RealPropertyDetails
     */
    'liveStream'?: Array<RealEvent>;
    /**
     * 
     * @type {RealParkingDetails}
     * @memberof RealPropertyDetails
     */
    'parkingDetails'?: RealParkingDetails;
    /**
     * 
     * @type {Array<RealPhoto>}
     * @memberof RealPropertyDetails
     */
    'propertyPhotos'?: Array<RealPhoto>;
    /**
     * 
     * @type {Array<RealUtility>}
     * @memberof RealPropertyDetails
     */
    'utilitiesAvailable'?: Array<RealUtility>;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealPropertyDetails
     */
    'amenitiesNearBy'?: Array<string>;
    /**
     * 
     * @type {RealListingInformation}
     * @memberof RealPropertyDetails
     */
    'listingInformation'?: RealListingInformation;
    /**
     * 
     * @type {Array<RealOfficeDetails>}
     * @memberof RealPropertyDetails
     */
    'realOfficeDetails'?: Array<RealOfficeDetails>;
    /**
     * 
     * @type {Array<RealWebsite>}
     * @memberof RealPropertyDetails
     */
    'websites'?: Array<RealWebsite>;
    /**
     * 
     * @type {GeoPoint}
     * @memberof RealPropertyDetails
     */
    'location'?: GeoPoint;
    /**
     * 
     * @type {string}
     * @memberof RealPropertyDetails
     */
    'listingType'?: RealPropertyDetailsListingTypeEnum;
    /**
     * 
     * @type {RealCharacteristics}
     * @memberof RealPropertyDetails
     */
    'realCharacteristics'?: RealCharacteristics;
    /**
     * 
     * @type {Array<string>}
     * @memberof RealPropertyDetails
     */
    'communityFeatures'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof RealPropertyDetails
     */
    'livingArea'?: string;
    /**
     * 
     * @type {RealTaxHistory}
     * @memberof RealPropertyDetails
     */
    'taxHistory'?: RealTaxHistory;
}

export const RealPropertyDetailsListingTypeEnum = {
    Buy: 'BUY',
    Rent: 'RENT',
    Unknown: 'UNKNOWN'
} as const;

export type RealPropertyDetailsListingTypeEnum = typeof RealPropertyDetailsListingTypeEnum[keyof typeof RealPropertyDetailsListingTypeEnum];

/**
 * 
 * @export
 * @interface RealResponsiveImages
 */
export interface RealResponsiveImages {
    /**
     * 
     * @type {Array<RealImage>}
     * @memberof RealResponsiveImages
     */
    'realImages'?: Array<RealImage>;
}
/**
 * 
 * @export
 * @interface RealRoom
 */
export interface RealRoom {
    /**
     * 
     * @type {string}
     * @memberof RealRoom
     */
    'type'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealRoom
     */
    'width'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealRoom
     */
    'level'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealRoom
     */
    'length'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealRoom
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealRoom
     */
    'dimension'?: string;
}
/**
 * 
 * @export
 * @interface RealTaxHistory
 */
export interface RealTaxHistory {
    /**
     * 
     * @type {number}
     * @memberof RealTaxHistory
     */
    'taxAnnualAmount'?: number;
    /**
     * 
     * @type {string}
     * @memberof RealTaxHistory
     */
    'taxBlock'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealTaxHistory
     */
    'taxLot'?: string;
    /**
     * 
     * @type {number}
     * @memberof RealTaxHistory
     */
    'taxYear'?: number;
}
/**
 * 
 * @export
 * @interface RealUtility
 */
export interface RealUtility {
    /**
     * 
     * @type {string}
     * @memberof RealUtility
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealUtility
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface RealWebsite
 */
export interface RealWebsite {
    /**
     * 
     * @type {string}
     * @memberof RealWebsite
     */
    'url'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealWebsite
     */
    'contactType'?: string;
    /**
     * 
     * @type {string}
     * @memberof RealWebsite
     */
    'websiteType'?: string;
}
/**
 * 
 * @export
 * @interface SearchPropertiesLocationRequest
 */
export interface SearchPropertiesLocationRequest {
    /**
     * 
     * @type {LatLong}
     * @memberof SearchPropertiesLocationRequest
     */
    'latLong'?: LatLong;
    /**
     * 
     * @type {string}
     * @memberof SearchPropertiesLocationRequest
     */
    'searchDistanceUnit'?: SearchPropertiesLocationRequestSearchDistanceUnitEnum;
    /**
     * 
     * @type {number}
     * @memberof SearchPropertiesLocationRequest
     */
    'distance'?: number;
    /**
     * 
     * @type {Filter}
     * @memberof SearchPropertiesLocationRequest
     */
    'filter'?: Filter;
    /**
     * 
     * @type {number}
     * @memberof SearchPropertiesLocationRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchPropertiesLocationRequest
     */
    'pageSize'?: number;
}

export const SearchPropertiesLocationRequestSearchDistanceUnitEnum = {
    Kilometers: 'KILOMETERS',
    Miles: 'MILES'
} as const;

export type SearchPropertiesLocationRequestSearchDistanceUnitEnum = typeof SearchPropertiesLocationRequestSearchDistanceUnitEnum[keyof typeof SearchPropertiesLocationRequestSearchDistanceUnitEnum];

/**
 * 
 * @export
 * @interface SearchPropertiesPolygonRequest
 */
export interface SearchPropertiesPolygonRequest {
    /**
     * 
     * @type {Array<LatLong>}
     * @memberof SearchPropertiesPolygonRequest
     */
    'points'?: Array<LatLong>;
    /**
     * 
     * @type {number}
     * @memberof SearchPropertiesPolygonRequest
     */
    'pageNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof SearchPropertiesPolygonRequest
     */
    'pageSize'?: number;
    /**
     * 
     * @type {Filter}
     * @memberof SearchPropertiesPolygonRequest
     */
    'filter'?: Filter;
}

/**
 * PropertiesControllerApi - axios parameter creator
 * @export
 */
export const PropertiesControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyDetails1: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPropertyDetails1', 'id', id)
            const localVarPath = `/api/v1/properties/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertiesControllerApi - functional programming interface
 * @export
 */
export const PropertiesControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertiesControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyDetails1(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPropertyDetails>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyDetails1(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertiesControllerApi - factory interface
 * @export
 */
export const PropertiesControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertiesControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyDetails1(id: string, options?: any): AxiosPromise<RealPropertyDetails> {
            return localVarFp.getPropertyDetails1(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertiesControllerApi - object-oriented interface
 * @export
 * @class PropertiesControllerApi
 * @extends {BaseAPI}
 */
export class PropertiesControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertiesControllerApi
     */
    public getPropertyDetails1(id: string, options?: AxiosRequestConfig) {
        return PropertiesControllerApiFp(this.configuration).getPropertyDetails1(id, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PropertySearchControllerApi - axios parameter creator
 * @export
 */
export const PropertySearchControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} query 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutoSuggestPropertiesCollection: async (query: string, pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('getAutoSuggestPropertiesCollection', 'query', query)
            const localVarPath = `/api/v1/search/properties/autosuggest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPropertyDetails: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPropertyDetails', 'id', id)
            const localVarPath = `/api/v1/search/property/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyDetailsCollection: async (pageNumber?: number, pageSize?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/search/properties`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageNumber !== undefined) {
                localVarQueryParameter['pageNumber'] = pageNumber;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPropertiesByLocationCollection: async (searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPropertiesLocationRequest' is not null or undefined
            assertParamExists('searchPropertiesByLocationCollection', 'searchPropertiesLocationRequest', searchPropertiesLocationRequest)
            const localVarPath = `/api/v1/search/property/radial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPropertiesLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPropertiesPolygonRequest} searchPropertiesPolygonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPropertiesByPolygonCollection: async (searchPropertiesPolygonRequest: SearchPropertiesPolygonRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPropertiesPolygonRequest' is not null or undefined
            assertParamExists('searchPropertiesByPolygonCollection', 'searchPropertiesPolygonRequest', searchPropertiesPolygonRequest)
            const localVarPath = `/api/v1/search/properties/polygon`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPropertiesPolygonRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPropertiesRadial: async (searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'searchPropertiesLocationRequest' is not null or undefined
            assertParamExists('searchPropertiesRadial', 'searchPropertiesLocationRequest', searchPropertiesLocationRequest)
            const localVarPath = `/api/v1/search/properties/radial`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(searchPropertiesLocationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PropertySearchControllerApi - functional programming interface
 * @export
 */
export const PropertySearchControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PropertySearchControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} query 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAutoSuggestPropertiesCollection(query: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPropertiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAutoSuggestPropertiesCollection(query, pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getPropertyDetails(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPropertiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyDetails(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPropertyDetailsCollection(pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPropertiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPropertyDetailsCollection(pageNumber, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async searchPropertiesByLocationCollection(searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPropertiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPropertiesByLocationCollection(searchPropertiesLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPropertiesPolygonRequest} searchPropertiesPolygonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPropertiesByPolygonCollection(searchPropertiesPolygonRequest: SearchPropertiesPolygonRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPropertiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPropertiesByPolygonCollection(searchPropertiesPolygonRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchPropertiesRadial(searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RealPropertiesResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchPropertiesRadial(searchPropertiesLocationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PropertySearchControllerApi - factory interface
 * @export
 */
export const PropertySearchControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PropertySearchControllerApiFp(configuration)
    return {
        /**
         * 
         * @param {string} query 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAutoSuggestPropertiesCollection(query: string, pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<RealPropertiesResponse> {
            return localVarFp.getAutoSuggestPropertiesCollection(query, pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getPropertyDetails(id: string, options?: any): AxiosPromise<RealPropertiesResponse> {
            return localVarFp.getPropertyDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [pageNumber] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPropertyDetailsCollection(pageNumber?: number, pageSize?: number, options?: any): AxiosPromise<RealPropertiesResponse> {
            return localVarFp.getPropertyDetailsCollection(pageNumber, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        searchPropertiesByLocationCollection(searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options?: any): AxiosPromise<RealPropertiesResponse> {
            return localVarFp.searchPropertiesByLocationCollection(searchPropertiesLocationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPropertiesPolygonRequest} searchPropertiesPolygonRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPropertiesByPolygonCollection(searchPropertiesPolygonRequest: SearchPropertiesPolygonRequest, options?: any): AxiosPromise<RealPropertiesResponse> {
            return localVarFp.searchPropertiesByPolygonCollection(searchPropertiesPolygonRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchPropertiesRadial(searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options?: any): AxiosPromise<RealPropertiesResponse> {
            return localVarFp.searchPropertiesRadial(searchPropertiesLocationRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PropertySearchControllerApi - object-oriented interface
 * @export
 * @class PropertySearchControllerApi
 * @extends {BaseAPI}
 */
export class PropertySearchControllerApi extends BaseAPI {
    /**
     * 
     * @param {string} query 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertySearchControllerApi
     */
    public getAutoSuggestPropertiesCollection(query: string, pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return PropertySearchControllerApiFp(this.configuration).getAutoSuggestPropertiesCollection(query, pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PropertySearchControllerApi
     */
    public getPropertyDetails(id: string, options?: AxiosRequestConfig) {
        return PropertySearchControllerApiFp(this.configuration).getPropertyDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [pageNumber] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertySearchControllerApi
     */
    public getPropertyDetailsCollection(pageNumber?: number, pageSize?: number, options?: AxiosRequestConfig) {
        return PropertySearchControllerApiFp(this.configuration).getPropertyDetailsCollection(pageNumber, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof PropertySearchControllerApi
     */
    public searchPropertiesByLocationCollection(searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options?: AxiosRequestConfig) {
        return PropertySearchControllerApiFp(this.configuration).searchPropertiesByLocationCollection(searchPropertiesLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPropertiesPolygonRequest} searchPropertiesPolygonRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertySearchControllerApi
     */
    public searchPropertiesByPolygonCollection(searchPropertiesPolygonRequest: SearchPropertiesPolygonRequest, options?: AxiosRequestConfig) {
        return PropertySearchControllerApiFp(this.configuration).searchPropertiesByPolygonCollection(searchPropertiesPolygonRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SearchPropertiesLocationRequest} searchPropertiesLocationRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PropertySearchControllerApi
     */
    public searchPropertiesRadial(searchPropertiesLocationRequest: SearchPropertiesLocationRequest, options?: AxiosRequestConfig) {
        return PropertySearchControllerApiFp(this.configuration).searchPropertiesRadial(searchPropertiesLocationRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


